<div id="resume-container">
  <div id="resume">
    <div id="header">
      <h1 class="no-margin">{{name}}</h1>
      <h2 class="no-margin">{{title}}</h2>
    </div>
    <div id="side-header">
      <blockquote *ngIf="description">{{description}}</blockquote>
    </div>
    <div id="side">
      <div class="side-section">
        <h1>Contact</h1>
        <div class="contact">
          <div>
            <a target="_blank" href="mailto:hi@austen.info">hi&#64;austen.info</a>
          </div>
          <div>
            <a target="_blank" href="https://austen.info/">austen.info</a>
          </div>
          <div>
            <a target="_blank" href="https://www.linkedin.com/in/austenstone">linkedin.com/in/austenstone</a>
          </div>
        </div>
      </div>
      <div class="side-section">
        <h1>Skills</h1>
        <mat-chip-set class="skill-chips">
          <mat-chip [color]="'accent'" *ngFor="let skill of skills">
            {{skill}}
          </mat-chip>
        </mat-chip-set>
      </div>
      <div class="side-section">
        <h1>Education</h1>
        <a target="_blank" href="https://www.fau.edu/">
          <div class="education">
            <div class="title">
              <h1>Florida Atlantic University</h1>
              <h2 class="accent-light">Bachelors of Science in Computer Engineering</h2>
            </div>
          </div>
        </a>
      </div>
    </div>
    <div id="content">
      <h1 class="banner">Experience</h1>
      <div class="experience" *ngFor="let experience of workExperiences">
        <div class="experience-header">
          <div class="title">
            <h1>{{experience.title}}</h1>
            <a *ngIf="experience.href" target="_blank" [href]="experience.href">
              <h2 class="company-name primary-light">{{experience.name}}</h2>
            </a>
            <h2 *ngIf="!experience.href" class="primary-light">{{experience.name}}</h2>
          </div>
          <span class="flexer"></span>
          <span class="date primary-light">
            {{experience.dateStart | date:'y'}} - {{experience.dateEnd ? (experience.dateEnd| date:'y') : 'Present'}}
          </span>
        </div>
        <div>
          <!-- <p>
            {{experience.description}}
          </p> -->
          <ul>
            <li *ngFor="let bullet of experience.bullets">{{bullet}}</li>
          </ul>
        </div>
      </div>
      <p class="display-none print-only">
        <a target="_blank" href="https://austen.info/resume">
          View online at: austen.info/resume
        </a>
      </p>
    </div>
  </div>
</div>
